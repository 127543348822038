import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { NavigationService } from '../../../services/navigation.service';
import { Router } from '@angular/router';
import { VendorProfileService } from './services/vendor-profile.service';
import { UpdateAddressFormComponent } from './components/vendor-addresses/update-address-form/update-address-form.component';
import { UpdateSocialInfoComponent } from './components/vendor-contact-info/update-social-info/update-social-info.component';
import { UpdateVendorCategoriesComponent } from './components/vendor-categories/update-vendor-categories/update-vendor-categories.component';
import { RequestWithdrawalPointsForVendorComponent } from '../request-withdrawal-points-for-vendor/request-withdrawal-points-for-vendor.component';
import { ClaimedPointOfferService } from '../../../shared/claim-point-offer/services/claimPointOffer.service';
import { UpdateBankAccountComponent } from './components/bank-account-data/update-bank-account/update-bank-account.component';
import { WorkingHoursFormComponent } from '../../../shared/working-hours-form/working-hours-form.component';
import { WorkingHoursService } from '../../vendors/services/working-hours.service';

@Component({
  selector: 'app-vendor-admin-profile',
  templateUrl: './vendor-admin-profile.component.html',
  styles: ``
})
export class VendorAdminProfileComponent {
  dataLoading = false;

  showSearch = false;
  showFilter = false;

  pageIndex = 0;
  pageSize = 10;
  pageCount = 0;
  recordsData!: any | null;
  filterModel: any;

  vendorDetails: any;
  permissions: any
  selectedVendorId!: any
  isClosedToday!: boolean;
  isOpenAllDay!: boolean;
  startTime!: any;
  closedTime!: any;
  lang = localStorage.getItem('language') || 'ar';
  workingHours!: any | null;
  workHours: any[] = [];
  constructor(private dialog: MatDialog,
    private router: Router,
    private navService: NavigationService,
    private service: VendorProfileService,
    private authService: AuthenticationService,
    private _ClaimedPointOfferService: ClaimedPointOfferService,
    private workingHoursService: WorkingHoursService) { }


  ngOnInit(): void {
    this.getVendorDetails();
    setTimeout(() => {
      this.navService.sidebarState.sidenavOpen = false;
    }, 10);

    this._ClaimedPointOfferService.RefreshWallet.subscribe((res: boolean) => {
      if (res) {
        this.getVendorDetails();
      }
    })
  }



  requestWithdrawalRequest() {
    const dialogRef = this.dialog.open(RequestWithdrawalPointsForVendorComponent, {
      width: '540px',
      data: { balance: this.vendorDetails.balance.balance }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })

  }

  getVendorDetails() {
    let vendorId = this.authService.currentUserValue?.vendor_id || ''
    this.dataLoading = true;
    this.service.GetVendorProfile(vendorId).subscribe({
      next: (res: any) => {
        this.dataLoading = false;
        this.vendorDetails = res.data;
        this.permissions = res.permissions;
        this.getAllBranchWorkingHours();
      },
      error: (error: any) => {
        this.dataLoading = false;
      }
    })
  }


  updateVendorAddress() {
    const dialogRef = this.dialog.open(UpdateAddressFormComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateVendorContactInfo() {
    const dialogRef = this.dialog.open(UpdateSocialInfoComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateVendorCategories() {
    const dialogRef = this.dialog.open(UpdateVendorCategoriesComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  updateBankAccountDetails() {
    const dialogRef = this.dialog.open(UpdateBankAccountComponent, {
      width: '540px',
      data: { details: this.vendorDetails }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }

  convert24To12Time(time: any) {
    let hour = (time.split(':'))[0]
    let min = (time.split(':'))[1]
    let part;
    if (this.lang == 'en') {
      part = (hour >= 12 && min) ? 'PM' : 'AM';
    } else {
      part = (hour >= 12 && min) ? 'م' : 'ص';
    }
    min = (min + '').length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + '').length == 1 ? `0${hour}` : hour;
    if (hour + ':' + min == '00:00') {
      hour = '12';
      min = '00';
      if (this.lang == 'en') {
        part = 'AM';
      } else {
        part = 'ص';
      }
    } else if (hour + ':' + min == '12:00') {
      hour = '12';
      min = '00';
      if (this.lang == 'en') {
        part = 'PM';
      } else {
        part = 'م';
      }
    }
    return `${hour}:${min} ${part}`
  }

  getAllBranchWorkingHours() {
    this.dataLoading = true;
    this.workingHoursService.getAll(this.vendorDetails?.main_branch_id).subscribe((res) => {
      this.recordsData = res;
      this.workHours = res.data.working_hours;
      this.isClosedToday = (res.data.branch_is_open_and_day_time.is_open == 1) ? false : true;
      this.isOpenAllDay = (res.data.branch_is_open_and_day_time.is_open_all_day == 1) ? true : false;
      if (res.data.branch_is_open_and_day_time?.start_at) {
        this.startTime = this.convert24To12Time(res.data.branch_is_open_and_day_time?.start_at);
      }
      if (res.data.branch_is_open_and_day_time?.closed_at) {
        this.closedTime = this.convert24To12Time(res.data.branch_is_open_and_day_time?.closed_at);
      }
      this.workingHours = JSON.parse(JSON.stringify(this.recordsData));
      this.workingHours?.data.working_hours.map((day: any) => {
        if (!day.is_closed) {
          day.start_at = this.convert24To12Time(day.start_at);
          day.closed_at = this.convert24To12Time(day.closed_at);
        }
      })
      this.dataLoading = false;
    })
  }

  configWorkingHours() {
    const dialogRef = this.dialog.open(WorkingHoursFormComponent, {
      width: '540px',
      data: { working_hours: this.workHours, branchId: this.vendorDetails?.main_branch_id }
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getVendorDetails()
      }
    })
  }
}
