import { ApplicationRef, Component, HostListener, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './services/StorageService';
import { AuthenticationService } from './services/authentication.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, filter, first, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  title = 'wala-X-app';

  constructor(
    public storageService: StorageService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private _authService: AuthenticationService,
    swUpdate: SwUpdate,
    appRef: ApplicationRef
    // private sw: UpdateAppService,
    // upToDateService: UpToDateBuildService
  ) {
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await swUpdate.checkForUpdate();
        if (updateFound) document.location.reload();
        // console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });
    // swUpdate.versionUpdates
    //   .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
    //   .subscribe(evt => {
    //     if (confirm('A new version is available. 4')) {
    //       document.location.reload();
    //     }
    //   });
    // this.sw.checkForUpdates();
    // let buildNumber = upToDateService.buildNumberAtStartup;
    // upToDateService.buildIsUpToDate.subscribe(buildIsUpToDate => {
    //   if (!buildIsUpToDate) {
    //     Swal.fire({
    //       title: this.translate.instant('general.New app version found.'),
    //       icon: 'info',
    //       confirmButtonText: this.translate.instant('swalMsg.Reload'),
    //     }).then(() => {
    //       window.location.reload();
    //     });
    //   }
    // });

    this.storageService.changes.subscribe(data => {
      if (data?.key == 'language' || data?.key == 'authUser') {
        window.location.reload();
      }
    });

    var lang = localStorage.getItem('language');
    if (lang == 'ar' || lang == 'en') {
      translate.use(lang);
    } else {
      lang = 'ar';
      translate.use('ar');
      localStorage.setItem("language", lang)
    }
    var style = './assets/css/en-US/style.css';
    var dir = 'ltr';
    if (lang == 'ar') {
      style = './assets/css/ar-SA/style.css';
      dir = 'rtl';
    }
    let buildNumber = '1.0.3'
    style += buildNumber ? '?vr=' + buildNumber : '';
    document.getElementById('mainStyle')?.setAttribute('href', style);
    document.getElementById('html')?.setAttribute('lang', lang);
    document.getElementById('html')?.setAttribute('dir', dir);
  }
  ngOnInit(): void {
  }
}
