<div class="dialog">
    <div class="dialog_header">
        <button mat-icon-button class="close" [disabled]="loading" (click)="openConfirmDialog()"><svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                <path id="ic_close"
                    d="M213.746-744.393l-6.441,6.436a1.459,1.459,0,0,1-1.063.457,1.406,1.406,0,0,1-1.046-.457,1.418,1.418,0,0,1-.45-1.054,1.449,1.449,0,0,1,.45-1.037l6.441-6.452-6.441-6.452a1.419,1.419,0,0,1-.441-1.037,1.473,1.473,0,0,1,.441-1.053,1.406,1.406,0,0,1,1.046-.457,1.485,1.485,0,0,1,1.063.441l6.441,6.452,6.458-6.452a1.431,1.431,0,0,1,1.046-.441,1.458,1.458,0,0,1,1.063.457,1.443,1.443,0,0,1,.433,1.053,1.448,1.448,0,0,1-.45,1.037l-6.441,6.452,6.441,6.452a1.419,1.419,0,0,1,.441,1.037,1.473,1.473,0,0,1-.441,1.054,1.406,1.406,0,0,1-1.046.457,1.406,1.406,0,0,1-1.046-.457Z"
                    transform="translate(-204.746 755.5)" fill="#999" />
            </svg>
        </button>
        {{"general.Edit"| translate}} {{ 'companyAdminProfile.Address and contact information' | translate }}
    </div>
    <form [formGroup]="formGroup">
        <div class="dialog_content pb-0" mat-dialog-content>

            <div class="title">{{"companies.Company Address"|translate}}</div>
            <div class="fields-group mb-3" *ngIf="!isUpdate">
                <div class="ng-select-field">
                    <ng-select ngSelectMat appearance="fill"
                        [class.ng-invalid]="hasError('city_id','required')" formControlName="city_id"
                        [items]="CitesList" [virtualScroll]="true" [loading]="loadingCities" bindLabel="name"
                        bindValue="id" [placeholder]='"companies.The City" | translate'
                        [notFoundText]='"general.No Data Found" | translate'
                        [loadingText]='"forms.Loading..." | translate' (search)="dropSearch($event)" (clear)="OnClear()"(change)="citesModal.search='';"
                        dropdownPosition="bottom" [clearable]="false" [disabled]="isUpdate">
                        <!-- <ng-template ng-header-tmp *ngIf="CitesList?.length && !loadingCities">
                            <small class="form-text text-muted">{{'forms.Loaded' | translate}}
                                {{CitesList.length}} {{'forms.of' | translate}} {{citiesCount}}</small>
                        </ng-template> -->
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div title="{{item.name}}">{{item.name}}</div>
                        </ng-template>
                    </ng-select>
                    <mat-error class="has-error" *ngIf="hasError('city_id','required')">
                        {{"form_message.Required"|translate}}
                    </mat-error>
                </div>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field class="arabic-form-field">
                    <mat-label>العنوان التفصيلي (باللغة العربية)</mat-label>
                    <textarea matInput rows="5" maxlength="100" formControlName="address_ar"></textarea>
                    <mat-hint class="pt-1">{{"general.optionalAR"|translate}}</mat-hint>
                    <mat-error *ngIf="hasError('address_ar', 'pattern')">{{ 'forms.Enter address (in Arabic)' |
                        translate
                        }}</mat-error>

                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field class="english-form-field">
                    <mat-label>Street Address (in English)</mat-label>
                    <textarea matInput rows="5" maxlength="100" formControlName="address_en"></textarea>
                    <mat-hint class="pt-1">{{"general.optionalEn"|translate}}</mat-hint>
                    <mat-error *ngIf="hasError('address_en', 'pattern')">{{ 'forms.Enter address (in English)' |
                        translate
                        }}</mat-error>

                </mat-form-field>
            </div>
            <div class="title">{{"companies.Contact Person"|translate}}</div>
            <div class="fields-group mb-3">
                <mat-form-field class="phoneNumberleft">
                    <mat-label>{{ "general.Mobile Number" | translate }}</mat-label>
                    <input matInput type="text" appOnlynumber maxlength="10" minlength="8"
                        formControlName="company_phone_number">
                    <span matSuffix>+966 </span>
                    <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>
                    <mat-error *ngIf="hasError('company_phone_number','minlength')">
                        {{"form_message.at least characters"|translate :{num:8} }}
                    </mat-error>
                    <mat-error *ngIf="hasError('company_phone_number', 'pattern')">
                        {{ 'forms.Invalid value' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="fields-group mb-3">
                <mat-form-field>
                    <mat-label>{{"companies.Email"|translate}}</mat-label>
                    <input matInput type="email" maxlength="100" formControlName="company_email">
                    <mat-error *ngIf="hasError('company_email','pattern')">
                        {{"form_message.Invalid email"|translate}}
                    </mat-error>
                    <mat-hint class="pt-1">{{"general.optional"|translate}}</mat-hint>

                </mat-form-field>
            </div>


        </div>



        <div class="dialog_actions" mat-dialog-actions>
            <button mat-button type="button" [disabled]="loading" class="btn-secondary"
                (click)="openConfirmDialog()">{{"general.Cancel"|translate}}</button>

            <button mat-button type="button" [disabled]="loading" [class.loading]="loading" (click)="OnSave()"
                class="btn-primary">
                {{"general.Edit"| translate}}
            </button>
        </div>
    </form>
</div>