import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WorkingHoursFormComponent } from '../../../../../shared/working-hours-form/working-hours-form.component';
import { WorkingHours } from '../../../../vendors/models/workingHours';
import { WorkingHoursService } from '../../../../vendors/services/working-hours.service';

@Component({
  selector: 'app-vendor-working-hours',
  templateUrl: './vendor-working-hours.component.html',
  styles: ``
})
export class VendorWorkingHoursComponent {
  @Input() vendorDetails!: any;
  @Input() isClosedToday!: boolean;
  @Input() isOpenAllDay!: boolean;
  @Input() recordsData!: any;
  @Input() startTime!: any;
  @Input() closedTime!: any;

  mainBranchId!: any;
  dataLoading = false;

  constructor(private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }
}
