<!-- PAGE LOADER -->
<div class="page-progress">
  <mat-progress-bar mode="indeterminate" *ngIf="moduleLoading"></mat-progress-bar>
</div>

<div class="content-grid">
  <div class="app-hub-content">
    <div class="app-sidebar-container" [ngClass]="{'full-width': !navService.sidebarState.sidenavOpen}">
      <!-- Sidebar -->
      <div class="app-sidebar-wrap" [class.mb-audioHight]="viewAudioPlayer">
        <app-sidebar class="sidebar-wrapper"></app-sidebar>
      </div>
      <!-- Content page -->
      <div class="app-content-wrap"[class.mb-audioHight]="viewAudioPlayer">
        <!-- Header -->
        <app-header></app-header>
        <div class="app--body-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
  <app-audio-player *ngIf="viewAudioPlayer" class="m-auto"></app-audio-player>
</div>