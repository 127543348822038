<div class="content">
    <p class="font-14 fontRegular text-color mb-0">{{"vendors.Bank Account Data"|translate}}</p>
    <div class="info-data" *ngIf="vendorDetails?.bank_name">
        <!-- <div class="icon-card">
            <img src="./assets/images/icons/commercial_reg_num_ic.svg" alt="">
        </div> -->
        <div>
            <label>{{'vendors.bank_name' | translate}}</label>
            <div>{{vendorDetails?.bank_name}}</div>
        </div>
    </div>
    <!-- <div class="info-data" *ngIf="vendorDetails?.bank_address">
        <div>
            <label>{{'vendors.bank_address' | translate}}</label>
            <div>{{vendorDetails?.bank_address}}</div>
        </div>
    </div>
    <div class="info-data" *ngIf="vendorDetails?.bank_account_name">
        <div>
            <label>{{'vendors.bank_account_name' | translate}}</label>
            <div>{{vendorDetails?.bank_account_name}}</div>
        </div>
    </div>
    <div class="info-data" *ngIf="vendorDetails?.bank_account_number">
        <div>
            <label>{{'vendors.bank_account_number' | translate}}</label>
            <div>{{vendorDetails?.bank_account_number}}</div>
        </div>
    </div> -->
    <div class="info-data" *ngIf="vendorDetails?.iban">
        <div>
            <label>{{'vendors.iban' | translate}}</label>
            <div>{{vendorDetails?.iban}}</div>
        </div>
    </div>
    <div class="info-data" *ngIf="vendorDetails?.swift_code">
        <div>
            <label>{{'vendors.swift_code' | translate}}</label>
            <div>{{vendorDetails?.swift_code}}</div>
        </div>
    </div>
</div>